<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex  md12>
                <material-card :title="title">
                    <v-data-table :headers="headers"
                                  :items="factories"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>
                        <template v-slot:item.users="{ item }">
                            <div v-for="user in item.users">
                                {{ user.email }}
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                  color="blue-grey darken-4"
                                  fab
                                  text
                                  @click="edit_factory(item)"
                                >
                                  <v-icon>
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                        </template>

                    </v-data-table>


                </material-card>
            </v-flex>

        </v-layout>


    <v-dialog v-model="factory_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="pa-6">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="factory_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="factory_dialog = false">Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-card-text>
              <span class="title">
                  Editing, {{ factory.name }}
              </span>
              <v-divider></v-divider>
              <v-text-field
                    v-model="factory.name"
                    :counter="200"
                    label="Name"
                  ></v-text-field>
              <v-text-field
                      v-model="factory.address"
                      :counter="400"
                      label="Address"
              ></v-text-field>

              <v-text-field
                    v-model="factory.zip_code"
                    :counter="10"
                    label="Zip Code"
                  ></v-text-field>

              <v-select
                      v-model="factory.country"
                      :items="countries"
                      item-value="id"
                      item-text="name"
                      label="Country"
              ></v-select>

              <v-card>
                  <v-card-title class="text-center">
                      <v-icon @click="open_fact_select()">
                          mdi-plus
                      </v-icon>
                  </v-card-title>
                  <v-simple-table>
                      <template v-slot:default>
                          <thead>
                          <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">Contact Info</th>
                              <th>
                                  Actions
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in factory.users">
                              <td>{{ item.first_name }} {{ item.last_name }}</td>
                              <td>
                                  <strong>
                                      Email:
                                  </strong> {{ item.email }} <br>
                                  <strong>
                                      Phone Number:
                                  </strong> <br>
                                  <strong>
                                      Phone Extension: {{ item.phone_extension }}
                                  </strong> <br>
                                  <strong>
                                      Fax: {{ item.fax }}
                                  </strong> <br>
                                  <strong>
                                      Mobile: {{ item.mobile }}
                                  </strong> <br>
                              </td>
                              <td>
                                  <v-btn
                                        color="blue-grey darken-4"
                                        @click="removeUser(factory, item, index)"
                                        text
                                        fab
                                      >
                                        <v-icon>
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                              </td>
                          </tr>
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-card>

          </v-card-text>

        <v-card-actions>
            <v-btn
                  color="blue-grey darken-4"
                  @click="save_factory(factory)"
                  outlined
                  block
                >
                  Save
                </v-btn>
        </v-card-actions>
      </v-card>
        <v-dialog v-model="factory_user_dialog" width="600">
            <v-card>
                <v-card-title>
                    Add User
                </v-card-title>
                <v-card-text>
                    <v-alert type="info">
                        You must add users before they show, <a target="_blank"
                                                                href="/c-panel/org/users">click here</a>
                        to go to the users section and
                        add new users.
                    </v-alert>

                    <v-select
                            v-model="user_to_add"
                            :items="users"
                            item-value="id"
                            item-text="email"
                            label="Select a User"
                    ></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                          color="blue-grey darken-4"
                          @click="add_user_to_factory(user_to_add, factory)"
                        >
                          Add User
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
    </v-container>

</template>

<script>
    export default {
        name: "factories",
        data: () => ({
            title: 'All factories',
            form_title: '',
            db_link:'',
            form_type:'new',
            factories: [],
            headers: [
                {
                    text: 'Factory Name',
                    align: 'center',
                    value: 'name'
                },
                {
                    text: 'Address',
                    align: 'center',
                    value: 'address'
                },
                {
                    text: 'Users',
                    align: 'center',
                    value: 'users'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true
                },
            ],
            users: [],
            countries: [],
            factory_dialog: false,
            factory: {},
            default_factory: {},
            factory_user_dialog: false,
            user_to_add: {},
            loading:true,
        }),
        created () {
            this.load_factories();
        },
        methods: {
            add_user_to_factory(user, factory)
            {
                console.log(user);
                console.log(factory);

                this.$hpost(this.$http.defaults.baseURL + '/factories/add-user/', {
                    user_id:user,
                    dep_id: factory.id
                }).then((response) => {
                    if(typeof response.data !== 'undefined')
                    {
                        let us = this.users.find(o => o.id === user);
                        factory.users.push(us)
                        this.factory_user_dialog = false;
                    }
                });
            },
            open_fact_select()
            {
                this.user_to_add = {};
                this.factory_user_dialog = true;
            },
            save_factory(factory)
            {
                console.log(factory);
                this.$hpatch(
                    this.$http.defaults.baseURL + '/factories/departments/'+factory.id+'/', factory).then((response) => {
                    if(typeof response.data !== 'undefined')
                    {
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully Saved Factory',
                            group: 'notifications',
                            position: 'top right'
                        });
                        this.factory_dialog = false;
                    }
                    });
            },
            removeUser(factory, user, index)
            {
                if(confirm('Are you sure you want to remove this user from this factory department ?'))
                {
                    console.log(factory);
                    console.log(user);
                    console.log(index);
                    this.$hpost(this.$http.defaults.baseURL + '/factories/remove-user/', {
                        department_id: factory.id,
                        user_id: user.id
                    }).then((response) => {
                        if(typeof response.data !== 'undefined')
                        {
                            factory.users.splice(index, 1)
                        }
                    });
                }
            },
            load_factories()
            {
                this.factories = [];
                this.users = [];
                this.$hget('/factories/organisations/')
                    .then((response)=>{
                        let self = this;
                        this.loading = false;

                        response.data.results.forEach(function(f){
                            f.users.forEach(function(u){
                                self.users.push(u)
                            });

                            f.departments.forEach(function(d){
                                self.factories.push(d);
                                d.users.forEach(function(u){
                                    let ex_us = self.users.find(o => o.id === u.id);
                                    if( typeof ex_us !== 'undefined')
                                    {
                                        self.users.push(u);
                                    }
                                });
                            });
                        });
                        this.load_countrie();
                    })
            },
            load_countrie()
            {
                this.$hget(
                    this.$http.defaults.baseURL + '/tools/countries/').then((response) => {
                        this.countries = response.data
                    });
            },
            edit_factory(factory)
            {
              this.factory = factory;
              this.factory_dialog = true;
            },
            seekValue(item, value){
                if(value.includes('.')) {
                    let map = value.split('.');
                    let res = item;
                    for (let pos of map)
                        res = res[pos] ? res[pos] : res;
                    return res;
                }else return item[value];


            }
        }
    }
</script>

<style scoped>

</style>
